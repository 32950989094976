
header {
  /* Create grid spanning viewport width & height */
  display: grid;
  grid-template-rows: 100vh;
  overflow: hidden;
  box-shadow: 0 0.2em 0.5em rgba(0, 0, 0, 0.5);
}

.video-bg {
  /* Span the full grid */
  grid-area: var(--fullGrid);

  /* Re-size video to cover full screen while maintaining aspect ratio */
  min-width: 100%;
  min-height: 100%;
  object-fit: cover;

  /* Display video below overlay */
  z-index: -1;
}

.video-bg::-webkit-media-controls {
  display: none !important;
}

.video-overlay {
  /* Span the full grid */
  grid-area: var(--fullGrid);

  /* Center Content */
  display: grid;
  justify-content: center;
  align-content: center;
  text-align: center;

  /* Semi-transparent background */
  background-color: rgba(0, 0, 0, 0.55);
}
h1 {
  font-size: calc(1.8em + 2.5vw);
  font-weight: 700;
  line-height: 1.5;
  letter-spacing: 0.02em;
  color: #fff;
  text-shadow: 0.05em 0.05em 0.05em rgba(0, 0, 0, 0.4);
}
.navbar.fixed{
  background-color: #686868;
  transition: .3s ease-in-out;
  border-bottom: 1px solid #E0E0E0;

}
.navbar{
  min-height:100px;
}
.img__logo{
  width: 11%;
}
@media (min-width: 768px){
  .navbar-nav>li>a {
      padding-top: 35px;
      padding-bottom: 35px;
  }
}
@media (max-width: 768px){
  .navbar-brand{
    float: initial;
  }
  .img__logo{
    width: 18%;
    margin-left: 20px;
  }
  .navbar-toggle{
    margin-top: 20px!important;
  }
  .titan-title-size-1 {
    width: 80%;
    margin: auto;
  }
  .card__team{
    width: 100%!important;
  }
  .navbar-custom{
    background-color: #000!important;
  }
  .video-bg{
    margin-top: 100px;
  } 
  .heading__header, .subheading__header{
    display: none;
  }
}
.navbar-custom {
  background-color: transparent;
}
.navbar-collapse.collapse.in{
  background-color: #000;
}
.home-section{
  margin-top: 0;
}


.content-brands{
  display: grid;
  align-items: center;
  align-content: center;
  text-align: center;
  border-radius: 10px;
  margin: 10px;
}
.carousel-row{
  margin: 10px;

}
.card__team:nth-child(4){
order: 1;
}
.card__team:nth-child(3){
  order: 4;
}
.card__team:nth-child(1){
  order: 2;
}
.card__team:nth-child(2){
  order: 3;
}
.card__team:nth-child(5){
  order: 6;
}
.card__team{
  width: 33%;
}
.content__team{
  display: flex;
  width: 100%;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
}
.post-columns .carousel-track{
  height: 393px!important;
}